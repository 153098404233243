<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>gift-box-1</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.75,13.5H5.25v8.25a1.5,1.5,0,0,0,1.5,1.5h10.5a1.5,1.5,0,0,0,1.5-1.5Z"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="3.75"
      y="9"
      width="16.5"
      height="4.5"
      rx="0.75"
      ry="0.75"
    />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="12" y1="9" x2="12" y2="23.25" />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="12" y1="2.25" x2="12" y2="0.75" />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="17.25"
      y1="3.311"
      x2="18.311"
      y2="2.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="20.307"
      y1="6.287"
      x2="21.693"
      y2="5.713"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="6.75"
      y1="3.311"
      x2="5.689"
      y2="2.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="3.693"
      y1="6.287"
      x2="2.307"
      y2="5.713"
    />
  </svg>
</template>
